export default {
	methods: {
		getLocalDate(configs = {}) {
			const { 
				timestamp = null,
				locale = 'de-DE',
				options = { year: 'numeric', month: '2-digit', day:'2-digit' }
			} = configs;
			const date = timestamp ? new Date(timestamp) : new Date();
			
			return date.toLocaleDateString(locale, options);
		},
	}
};
