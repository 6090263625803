import { createStore } from 'vuex';
import axios from 'axios';

let refreshTimeout;

export default createStore({
	state: {
		status: '',
		token: localStorage.getItem('token') || '',
		authToken: localStorage.getItem('auth_token') || '',
		user: JSON.parse(localStorage.getItem('user')) || {},
		imageBase: 'https://cms.biohandel.app/assets/',
		menuVisible: false,
		loading: false,
		lastAdvertorial: '',
		currentModules: [],
		redoModules: [],
	},
	mutations: {
		auth_request(state) {
			state.status = 'loading';
		},
		auth_success(state, token, authToken) {
			state.status = 'authenticated';
			state.token = token;
			state.authToken = authToken;
		},
		set_user(state, user) {
			state.user = user;
		},
		auth_error(state) {
			state.status = 'error';
		},
		logout(state) {
			state.status = '';
			state.token = '';
		},
		setMenu(state, menu) {
			state.menuVisible = menu;
		},
		setLoading(state, loading) {
			state.loading = loading;
		},
		setLastAdvertorial(state, id) {
			state.lastAdvertorial = id;
		},
		addRedoModule(state, module) {
			state.redoModules.push(module);
		},
		removeRedoModule(state, moduleId) {
			state.redoModules = state.redoModules.filter(
				(mod) => mod.id !== moduleId
			);
		},
		setCurrentModules(state, modules) {
			state.currentModules = modules;
		},
		setRedoModules(state, modules) {
			state.redoModules = modules;
		},
		removeCurrentModule(state, moduleId) {
			state.currentModules = state.currentModules.filter(
				(mod) => mod.id !== moduleId
			);
		},
	},
	actions: {
		updateLoading({ commit }, value) {
			commit('setLoading', value);
		},
		updateMenu({ commit }, value) {
			commit('setMenu', value);
		},
		setCurrentModules({ commit }, value) {
			commit('setCurrentModules', value);
		},
		removeCurrentModule({ commit }, value) {
			commit('removeCurrentModule', value);
			commit('removeRedoModule', value);
		},
		addRedoModule({ commit }, value) {
			console.log('addRedoModule', value);
			commit('addRedoModule', value);
		},
		resetModules({ commit }, value) {
			commit('setCurrentModules', value);
			commit('setRedoModules', []);
		},
		removeTokens() {
			localStorage.removeItem('token');
			localStorage.removeItem('auth_token');
			localStorage.removeItem('user');
			delete axios.defaults.headers['Authorization'];
		},
		async setTokens({ commit }, tokens) {
			localStorage.setItem('token', tokens.refreshToken);
			localStorage.setItem('auth_token', tokens.accessToken);
			axios.defaults.headers[
				'Authorization'
			] = `Bearer ${tokens.accessToken}`;
			commit('auth_success', tokens.refreshToken, tokens.accessToken);
			return true;
		},
		async refresh({ dispatch, getters }) {
			if (!getters.isLoggedIn || !this.state.token) return;
			// console.log(new Date().toString() + ' refresh token');
			try {
				const { data } = await axios({
					url: '/auth/refresh',
					data: { refresh_token: this.state.token },
					method: 'POST',
				});

				const accessToken = data.data.access_token;
				const refreshToken = data.data.refresh_token;
				const expires = data.data.expires;

				await dispatch('setTokens', { refreshToken, accessToken });
				// console.log(new Date().toString() + ' new token set')

				// Refresh the token every hour. Token expires in 7d. This means the user will stay
				// logged in without any noticable hickups or delays
				if (refreshTimeout) clearTimeout(refreshTimeout);
				refreshTimeout = setTimeout(
					() => dispatch('refresh'),
					expires - 3600000
				);

				return accessToken;
			} catch (error) {
				console.log(new Date().toString(), error);
				await dispatch('logout');
			}
		},
		async fetchUser({ commit, dispatch, getters }) {
			if (!getters.isLoggedIn) return;
			try {
				const { data } = await axios.get('/users/me?fields=*,role.*');
				localStorage.setItem('user', JSON.stringify(data.data));
				commit('set_user', data.data);
				return data.data;
			} catch (error) {
				await dispatch('logout');
			}
		},
		async updateUserProgress({ commit, getters }, update) {
			if (!getters.isLoggedIn || !getters.userId) return;
			try {
				const { data } = await axios.patch(
					`/users/${getters.userId}`,
					update
				);
				localStorage.setItem('user', JSON.stringify(data.data));
				commit('set_user', data.data);
				return data.data;
			} catch (error) {
				console.log('something went wrong', error);
			}
		},
		async login({ commit, dispatch }, user) {
			try {
				const res = await axios({
					url: '/auth/login',
					data: user,
					method: 'POST',
				});

				const accessToken = res.data.data.access_token;
				const refreshToken = res.data.data.refresh_token;
				const expires = res.data.data.expires;

				await dispatch('setTokens', { refreshToken, accessToken });
				refreshTimeout = setTimeout(
					() => dispatch('refresh'),
					expires - 3600000
				);
				await dispatch('fetchUser');
				return { token: accessToken, status: res.status };
			} catch (error) {
				console.log(error.response);
				commit('auth_error');
				dispatch('removeTokens');
				return {
					errors: error.response.data.errors,
					status: error.response.status,
				};
			}
		},
		logout({ commit, dispatch }) {
			return new Promise((resolve) => {
				dispatch('updateLoading', true);
				commit('logout');
				dispatch('removeTokens');
				dispatch('updateLoading', false);
				resolve();
			});
		},
	},
	getters: {
		isLoggedIn: (state) => !!state.token,
		authStatus: (state) => state.status,
		token: (state) => state.token,
		access_token: (state) => state.authToken,
		loading: (state) => state.loading,
		userId: (state) => state.user.id,
		currentModules: (state) => state.currentModules,
		role: (state) =>
			state.user && state.user.role ? state.user.role.name : '',
	},
});
