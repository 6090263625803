<template>
	<ion-icon
		v-if="!isChatVisibile && !isChatHidden"
		id="chatIcon"
		slot="icon-only"
		color="black"
		:icon="chatboxEllipsesOutline"
		@click="toggleAiChat()"
	></ion-icon>

	<vue-advanced-chat
		v-show="isChatVisibile"
		id="chatWindow"
		:height="getChatWindowHeight()"
		:current-user-id="currentUserId"
		:rooms="JSON.stringify(rooms)"
		:messages="JSON.stringify(messages)"
		:single-room="true"
		:messages-loaded="messagesLoaded"
		:is-waiting-for-reply="isWaitingForReply"
		:show-audio="false"
		:show-files="false"
		:show-emojis="false"
		:show-reaction-emojis="false"
		:styles="JSON.stringify(chatStyles)"
		:text-messages="JSON.stringify(textMessages)"
		:message-actions="JSON.stringify(messageActions)"
		@fetch-messages="fetchMessages($event.detail[0])"
		@send-message="sendMessage($event.detail[0])"
	>
		<div slot="room-header" class="roomHeader">
			<div class="headingWrapper">
				<div
					class="roomAvatar"
					:style="`background-image: url(${require('../assets/img/logo-icon.svg')});`"
				></div>
				<div class="textEllipsis">
					<div class="roomName textEllipsis">{{ aiName }}</div>
				</div>
			</div>
			<div class="closeButton">
				<ion-icon
					slot="icon-only"
					color="black"
					:icon="closeOutline"
					@click="toggleAiChat()"
				></ion-icon>
			</div>
		</div>

		<div
			slot="spinner-icon-waiting-for-reply"
			class="loadingMessageContainer"
		>
			Einen kleinen Moment, ich durchsuche meinen Wissensspeicher.
			<ion-spinner name="dots"></ion-spinner>
		</div>
	</vue-advanced-chat>
</template>

<script>
import { IonIcon, IonSpinner } from '@ionic/vue';
import { closeOutline, chatboxEllipsesOutline } from 'ionicons/icons';
import { register } from '@webmarken/vue-advanced-chat';
register();

import helpers from '../mixins/helpers';

const AI_ID = '4322';
const AI_NAME = 'WaWiKI';

export default {
	components: {
		IonIcon,
		IonSpinner,
	},
	mixins: [helpers],
	data() {
		return {
			isChatVisibile: false,
			messagesLoaded: true,
			isWaitingForReply: false,
			windowWidth: window.innerWidth,
			currentUserId: '1234',
			chat: {},
			rooms: [
				{
					roomId: '1',
					users: [
						{ _id: '1234', username: 'John Doe' },
						{ _id: AI_ID, username: AI_NAME },
					],
				},
			],
			messages: [],
			chatStyles: {
				message: {
					background: '#72BA5B',
					color: '#ffffff',
					backgroundMe: '#616e7e',
					colorNewMessages: '#50ae30',
					colorTimestamp: '#f4f4f4',
				},
			},
			messageActions: [], //need empty array to remove default set of options
			textMessages: {
				ROOMS_EMPTY: 'Aucune conversation',
				ROOM_EMPTY: 'Aucune conversation sélectionnée',
				NEW_MESSAGES: 'Neue Nachrichten',
				MESSAGE_DELETED: 'Ce message a été supprimé',
				MESSAGES_EMPTY: 'Aucun message',
				CONVERSATION_STARTED: 'Gespräch vom',
				TYPE_MESSAGE: 'Stelle WaWiKI deine Frage!',
				SEARCH: 'Rechercher',
				IS_ONLINE: 'est en ligne',
				LAST_SEEN: 'dernière connexion ',
				IS_TYPING: 'est en train de taper...',
				CANCEL_SELECT_MESSAGE: 'Annuler Sélection',
			},
			aiId: AI_ID,
			aiName: AI_NAME,
			initialWindowInnerHeight: "100vh",
		};
	},
	watch: {
		isChatVisibile(isOpen) {
			if (this.windowWidth <= 991) {
				document.getElementById('cmpboxrecall').style.display = isOpen
					? 'none'
					: 'block';
			}
		},
	},
	computed: {
		isChatHidden() {
			return [
				'kurse',
				'kurseDetail',
				'module',
				'wawiDetail',
				'advertorial',
			].includes(this.$route.name);
		},
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
			this.initialWindowInnerHeight = window?.innerHeight ? `${window.innerHeight}px` : '100vh';
		});
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		async fetchMessages({ options }) {
			// console.log(options);
			if (options?.reset) {
				this.messages = await this.addMessages(true);
			} else {
				this.messages = await this.addMessages();
			}
		},
		async refactorMessages(messages) {
			let newMessages = [];
			for (let msg of messages) {
				// console.log(msg);
				newMessages.push({
					_id: msg.id,
					content: msg.content[0].text.value.replace(/【.*?】/g, ''),
					senderId:
						msg.role === 'assistant'
							? this.aiId
							: this.currentUserId,
					timestamp: new Date(msg.created_at * 1000)
						.toString()
						.substring(16, 21),
					date: this.getLocalDate({
						timestamp: msg.created_at * 1000,
					}),
				});
			}
			return newMessages;
		},
		async addMessages(reset = false) {
			let messages = [];
			if (reset || !this.chat.thread_id) {
				messages = [
					{
						_id: this.messages.length + 1,
						content:
							'Du hast eine Frage zu einem Bio-Produkt? Ich bin bereit!',
						senderId: this.aiId,
						username: this.aiName,
						timestamp: new Date().toString().substring(16, 21),
						date: this.getLocalDate(),
					},
				];
			} else {
				const { data } = await this.$http.get(
					'/ai-chat/chat/' + this.chat.thread_id
				);
				// console.log(data);
				this.messages = await this.refactorMessages(data);
			}
			// console.log(reset);
			this.messagesLoaded = true;

			return messages;
		},
		async sendMessage(message) {
			const waitTimeout = setTimeout(() => {
				if (this.isWaitingForReply) {
					this.addNoResponseMessage();
				}
			}, 90000);

			try {
				this.isWaitingForReply = true;
				this.messages = [
					...this.messages,
					{
						_id: this.messages.length,
						content: message.content,
						senderId: this.currentUserId,
						timestamp: new Date().toString().substring(16, 21),
						date: this.getLocalDate(),
					},
				];

				const { data } = await this.$http.post('/ai-chat/chat', {
					message: message.content,
					chat: this.chat,
				});

				this.chat = data.chat;
				this.messages = await this.refactorMessages(data.messages);
			} catch (error) {
				this.addNoResponseMessage();
			} finally {
				this.isWaitingForReply = false;
				clearTimeout(waitTimeout);
			}
		},
		getChatWindowHeight() {
			if (this.windowWidth > 991) {
				return '650px';
			}

			return this.initialWindowInnerHeight;
		},
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		toggleAiChat() {
			this.isChatVisibile = !this.isChatVisibile;
		},
		addNoResponseMessage() {
			this.messages.push({
				_id: this.messages.length + 1,
				content:
					'Sorry, es sieht so aus, als wäre mein Server gerade am Limit. Bitte gedulde dich noch einen Moment oder versuche es später erneut.',
				senderId: this.aiId,
				timestamp: new Date().toString().substring(16, 21),
				date: this.getLocalDate(),
			});
		},
	},
	setup() {
		return { closeOutline, chatboxEllipsesOutline };
	},
};
</script>

<style lang="scss">
#chatIcon {
	width: 3rem;
	height: 3rem;
	position: absolute;
	bottom: 0.5rem;
	right: 2rem;
	font-size: 3rem;
	padding: 1rem;
	background: #fce962;
	border-radius: 100%;
	cursor: pointer;
	box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}
#chatWindow {
	position: absolute;
	right: 2rem;
	bottom: 2rem;
	width: 30rem;

	.roomHeader {
		display: flex;
		align-items: center;
		min-width: 0;
		height: 100%;
		width: 100%;
		padding: 0 16px;

		.headingWrapper {
			display: flex;
			align-items: center;
			min-width: 0;
			width: 100%;
			height: 100%;

			.roomAvatar {
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				background-color: #ddd;
				height: 42px;
				width: 42px;
				min-height: 42px;
				min-width: 42px;
				margin-right: 15px;
				border-radius: 50%;
			}

			.textEllipsis {
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.roomName {
				font-size: 17px;
				font-weight: 500;
				line-height: 22px;
				color: var(--chat-header-color-name);
			}
		}

		.closeButton {
			font-size: 1.5rem;
			display: flex;
			cursor: pointer;
		}
	}

	.loadingMessageContainer {
		display: flex;
		flex-wrap: wrap;

		background-color: #72ba5b;
		border-radius: 10px;
		color: white;
		width: 44%;
		padding: 10px;
		font-size: 14px;
	}
}

@media (max-width: 991px) {
	#chatIcon {
		bottom: 4rem;
		right: 1rem;
		width: 2rem;
		height: 2rem;
	}
	#chatWindow {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
	}
}
</style>
