<template>
	<ion-content :fullscreen="true">
		<div id="login">
			<form class="login-form" @submit.prevent="login()">
				<ion-img :src="require('../assets/img/logo.svg')" />
				<ion-item>
					<ion-label class="ion-margin-end">
						<ion-icon :icon="mailOutline"></ion-icon>
					</ion-label>
					<ion-input
						required
						v-model="email"
						type="email"
						placeholder="Email"
					></ion-input>
				</ion-item>
				<ion-item>
					<ion-label class="ion-margin-end">
						<ion-icon :icon="lockClosedOutline"></ion-icon>
					</ion-label>
					<ion-input
						required
						v-model="password"
						type="password"
						placeholder="Passwort"
					></ion-input>
				</ion-item>
				<ion-button
					expand="full"
					type="submit"
					color="black"
					class="ion-margin-top"
				>
					Anmelden
					<ion-icon slot="end" :icon="logInOutline"></ion-icon>
				</ion-button>
				<ion-note class="ion-margin-top" @click="setOpenPw(true)">
					Passwort vergessen
				</ion-note>
			</form>
		</div>
		<ion-alert
			:is-open="isOpenRef"
			header="🙈 Ohoh!"
			sub-header="Das hat nicht geklappt:"
			:message="errorMsg"
			:buttons="['Ok']"
			@didDismiss="setOpen(false)"
		>
		</ion-alert>
	</ion-content>
</template>

<script>
import {
	IonContent,
	IonLabel,
	IonInput,
	IonItem,
	IonButton,
	IonIcon,
	IonNote,
	IonAlert,
	IonImg,
	alertController,
} from '@ionic/vue';
import { logInOutline, mailOutline, lockClosedOutline } from 'ionicons/icons';
import { ref } from 'vue';

export default {
	name: 'Login',
	components: {
		IonContent,
		IonLabel,
		IonInput,
		IonItem,
		IonButton,
		IonIcon,
		IonNote,
		IonAlert,
		IonImg,
	},
	data() {
		return {
			email: '',
			password: '',
			errorMsg: '',
			pwResetInputs: [
				{
					name: 'Email',
					id: 'email',
					value: this.email,
					placeholder: 'Bitte gib deine Email Addresse an.',
				},
			],
			pwResetButtons: [
				{
					text: 'Abbrechen',
					role: 'cancel',
					cssClass: 'secondary',
					handler: (blah) => {
						console.log('Confirm Cancel:', blah);
						this.setOpenPw(false);
					},
				},
				{
					text: 'Okay',
					handler: () => {
						console.log('Confirm Okay');
						this.resetPW();
					},
				},
			],
		};
	},
	setup() {
		const isOpenRef = ref(false);
		const setOpen = (state) => (isOpenRef.value = state);

		return {
			logInOutline,
			mailOutline,
			lockClosedOutline,
			isOpenRef,
			setOpen,
		};
	},
	methods: {
		async login() {
			console.log('login');
			let email = this.email;
			let password = this.password;
			this.$store.dispatch('updateLoading', true);
			try {
				const login = await this.$store.dispatch('login', {
					email,
					password,
				});
				this.$store.dispatch('updateLoading', false);
				if (
					login.status !== 200 &&
					login.errors &&
					login.errors.length > 0
				) {
					this.errorMsg = login.errors[0].message;
					this.setOpen(true);
				} else {
					this.setOpen(false);
					this.$router.push('/');
				}
			} catch (err) {
				console.log(err);
			}
		},
		async resetPW(alertData) {
			if (alertData.email && alertData.email !== '') {
				// trigger PW reset
				try {
					await this.$http.post(`/auth/password/request/`, {
						email: alertData.email,
					});
					alert('Passwort angefordert!');
				} catch (e) {
					alert('Das hat leider NICHT geklappt!');
					console.log(e);
				}
			}
		},
		async setOpenPw() {
			const alert = await alertController.create({
				header: 'Passwort zurücksetzen',
				subHeader:
					'Bist du sicher, dass Du Dein Passwort zurücksetzen willst?',
				message:
					'Du bekommst anschließend eine Email mit weiteren Informationen',
				inputs: [
					{
						name: 'email',
						type: 'email',
						value: this.email,
						placeholder: 'Bitte gib deine Email Addresse an.',
					},
				],
				buttons: [
					{
						text: 'Abbrechen',
						role: 'cancel',
					},
					{
						text: 'Zurücksetzen',
						handler: (alertData) => {
							this.resetPW(alertData);
						},
					},
				],
			});
			return alert.present();
		},
	},
};
</script>

<style lang="scss" scoped>
#login {
	display: flex;
	align-items: center !important;
	justify-content: center !important;
	width: 100%;
	height: 100%;
	background: url(/assets/img/loginbg.jpg);
	background-position: center center;
	background-size: cover;
	.login-form {
		width: 350px;
		max-width: 80vw;
		margin-top: -50px;
		background-color: white;
		padding: 20px;
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
		display: flex;
		flex-direction: column;

		ion-img {
			height: 6.5rem;
			margin-bottom: 15px;
			margin-top: 15px;
		}

		.button {
			margin-top: 35px;
		}

		ion-note {
			cursor: pointer;
			text-align: center;
			margin-top: 10px;
			font-size: 12px;
		}
	}
}
</style>
