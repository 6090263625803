<template>
	<IonApp>
		<IonSplitPane
			content-id="main-content"
			@ion-split-pane-visible="menuVisible"
		>
			<ion-menu
				content-id="main-content"
				type="overlay"
				:disabled="!$store.getters.isLoggedIn"
			>
				<div class="header-item" v-if="!platform.includes('mobile')">
					<ion-item class="greeting" lines="none">
						<ion-avatar slot="start">
							<img :src="imgLink" />
						</ion-avatar>
						<div class="greeting-container">
							<ion-list-header class="slabs"
								>Menü</ion-list-header
							>

							<ion-note>
								Wunderschönen {{ greeting }},
								{{ $store.state.user.first_name }}!</ion-note
							>
						</div>
					</ion-item>
				</div>
				<div class="mobile-header" v-else>
					<ion-list-header class="slabs">Menü</ion-list-header>
				</div>
				<ion-content>
					<ion-list id="inbox-list">
						<ion-menu-toggle
							auto-hide="false"
							v-for="(p, i) in appPages"
							:key="i"
						>
							<ion-item
								@click="selectedIndex = i"
								router-direction="root"
								:router-link="p.url"
								detail="false"
								class="hydrated"
								:class="{
									selected:
										$route.fullPath === p.url ||
										(p.url.length > 1 &&
											$route.path.includes(p.url)),
								}"
								v-if="!p.action"
							>
								<ion-icon
									slot="start"
									:color="iconColor(p.url)"
									:ios="p.iosIcon"
									:md="p.mdIcon"
								></ion-icon>
								<ion-label
									>{{ p.title }} {{ p.action }}</ion-label
								>
							</ion-item>

							<ion-item
								@click="p.action"
								detail="false"
								class="hydrated"
								v-else
							>
								<ion-icon
									slot="start"
									color="black"
									:ios="p.iosIcon"
									:md="p.mdIcon"
								></ion-icon>
								<ion-label>{{ p.title }}</ion-label>
							</ion-item>
						</ion-menu-toggle>
					</ion-list>
				</ion-content>
			</ion-menu>

			<ion-router-outlet id="main-content"></ion-router-outlet>
		</IonSplitPane>

		<ion-loading
			:is-open="showLoading"
			cssClass="loading"
			message="Bitte warten..."
		>
		</ion-loading>

		<ion-toast
			:is-open="updateExists"
			message="Eine neue Version der App ist verfügbar."
			color="primary"
			:buttons="[
				{
					side: 'end',
					text: 'Updaten',
					handler: () => refreshApp(),
				},
			]"
		>
		</ion-toast>

		<ai-chat ref="aiChat" v-if="$store.getters.isLoggedIn" />
	</IonApp>
</template>

<script>
import {
	IonApp,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
	IonNote,
	IonRouterOutlet,
	IonAvatar,
	IonSplitPane,
	IonLoading,
	IonToast,
	getPlatforms,
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import updates from './mixins/updates';
import {
	homeOutline,
	listOutline,
	newspaperOutline,
	peopleOutline,
	personOutline,
	logOutOutline,
	bookOutline,
	mailOutline,
	help,
	chatboxEllipsesOutline,
} from 'ionicons/icons';
import { mapState } from 'vuex';
import AiChat from '@/components/AiChat.vue';

export default defineComponent({
	name: 'App',
	components: {
		IonApp,
		IonContent,
		IonIcon,
		IonItem,
		IonLabel,
		IonList,
		IonListHeader,
		IonMenu,
		IonAvatar,
		IonMenuToggle,
		IonNote,
		IonRouterOutlet,
		IonSplitPane,
		IonLoading,
		IonToast,
		AiChat,
	},
	mixins: [updates],
	methods: {
		menuVisible(event) {
			this.$store.dispatch('updateMenu', event.detail.visible);
		},
		logout() {
			// console.log('logout');
			this.$store.dispatch('updateLoading', true);
			this.$store
				.dispatch('logout')
				.then(() => this.$router.go('/login'))
				.catch((err) => console.log(err));
		},
		iconColor(url) {
			if (
				this.$route.fullPath === url ||
				(url.length > 1 && this.$route.path.includes(url))
			)
				return 'primary';
			return 'black';
		},
		feedback() {
			// console.log('send feedback', this.location);
			this.location.href =
				'mailto:app-biohandel@bioverlag.de?subject=Feedback zur BioHandel App';
			return;
		},
		handleAiChatMenuClick() {
			this.$refs?.aiChat?.toggleAiChat();
		},
	},

	async beforeCreate() {
		// Set up App on reload
		// start refresh token loop and fetch user details
		await this.$store.dispatch('refresh');
		// console.log('refreshed in app.vue')
		this.$store.dispatch('fetchUser');
	},

	data() {
		return {
			showLoading: false,
		};
	},

	watch: {
		loading(newVal) {
			this.showLoading = newVal;
		},
	},

	computed: {
		...mapState(['loading']),
		location() {
			return window.location;
		},

		platform() {
			return getPlatforms();
		},
		imgLink() {
			return `${this.$store.state.imageBase}${
				this.$store.state.user.avatar ||
				'dcf0ce07-4687-4185-8589-b5f442200498'
			}?access_token=${localStorage.getItem('auth_token')}`;
		},
		greeting() {
			const myDate = new Date();
			const hrs = myDate.getHours();

			let greet = '';

			if (hrs < 12) greet = 'guten Morgen';
			else if (hrs >= 12 && hrs <= 17) greet = 'guten Tag';
			else if (hrs >= 17 && hrs <= 24) greet = 'guten Abend';

			return greet;
		},
		appPages() {
			let pages = [
				{
					title: 'Start',
					url: '/',
					iosIcon: homeOutline,
					mdIcon: homeOutline,
				},
				{
					title: 'Lern-Kurse',
					url: '/kurse',
					iosIcon: listOutline,
					mdIcon: listOutline,
				},
				{
					title: 'Waren-Wissen',
					url: '/wawi',
					iosIcon: bookOutline,
					mdIcon: bookOutline,
				},
				{
					title: 'WaWiKI',
					iosIcon: chatboxEllipsesOutline,
					mdIcon: chatboxEllipsesOutline,
					action: this.handleAiChatMenuClick,
				},
				{
					title: 'News',
					url: '/news',
					iosIcon: newspaperOutline,
					mdIcon: newspaperOutline,
				},
				{
					title: 'Profil',
					url: '/profil',
					iosIcon: personOutline,
					mdIcon: personOutline,
				},
				{
					title: 'Kontakt',
					iosIcon: mailOutline,
					mdIcon: mailOutline,
					action: this.feedback,
				},
				{
					title: 'Über',
					url: '/uber',
					iosIcon: help,
					mdIcon: help,
				},
				{
					title: 'Logout',
					iosIcon: logOutOutline,
					mdIcon: logOutOutline,
					action: this.logout,
				},
			];

			if (this.$store.getters.role === 'Ladner') {
				pages.splice(4, 0, {
					title: 'Mitarbeitende',
					url: '/mitarbeiter',
					iosIcon: peopleOutline,
					mdIcon: peopleOutline,
				});
			}
			return pages;
		},
	},

	setup() {
		const selectedIndex = ref(0);

		const route = useRoute();
		const router = useRouter();

		return {
			selectedIndex,
			router,
			homeOutline,
			listOutline,
			newspaperOutline,
			peopleOutline,
			personOutline,
			logOutOutline,
			mailOutline,
			help,
			isSelected: (url) => (url === route.path ? 'selected' : ''),
		};
	},
});
</script>

<style scoped lang="scss">
ion-split-pane {
	--side-max-width: 400px;
}

ion-menu ion-content {
	--background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
	--padding-start: 8px;
	--padding-end: 8px;
	--padding-top: 20px;
	--padding-bottom: 20px;
}

/* ion-menu.md ion-note {
	margin-bottom: 30px;
} */

ion-menu.md ion-list-header,
ion-menu.md ion-note {
	padding-left: 10px;
}

ion-menu.md ion-list#inbox-list ion-list-header {
	font-size: 22px;
	font-weight: 600;

	min-height: 20px;
}

ion-menu.md ion-item {
	--padding-start: 10px;
	--padding-end: 10px;
	border-radius: 4px;
}

ion-menu.md ion-item.selected {
	--background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
	color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
	color: #616e7e;
}

ion-menu.md ion-item ion-label {
	font-weight: 500;
}

ion-menu.ios ion-content {
	--padding-bottom: 20px;
}

ion-menu.ios ion-note {
	line-height: 24px;
	margin-bottom: 20px;
}

ion-menu.ios ion-item {
	--padding-start: 16px;
	--padding-end: 16px;
	--min-height: 50px;
	cursor: pointer;
}

ion-menu ion-item {
	cursor: pointer;
}

ion-menu.ios ion-item.selected ion-icon {
	color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
	font-size: 24px;
	color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
	margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
	padding-left: 16px;
	padding-right: 16px;
}

ion-menu.ios ion-note {
	margin-bottom: 8px;
}

ion-note {
	display: inline-block;
	font-size: 16px;

	color: var(--ion-color-medium-shade);
}

ion-item.selected {
	--color: var(--ion-color-primary);
}

.mobile-header {
	background-color: white;
	border-bottom: 1px solid var(--ion-color-light);

	ion-list-header {
		font-size: 18px;
		padding: 10px;
		align-items: center;

		.list-header-inner {
			justify-content: center;
		}
	}
}

.header-item {
	padding-top: 120px;
	display: flex;
	align-items: flex-end;
	background: url(/assets/img/dashbg.jpg);
	background-position: top center;
	background-size: cover;

	ion-list-header {
		font-size: 22px;
		font-weight: 600;
		min-height: 20px;
	}

	ion-item {
		--background: rgba(255, 255, 255, 0.8);
		border-radius: 0px !important;
	}

	.greeting-container {
		display: flex;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
	}

	ion-avatar {
		max-width: 20%;
		width: 4rem;
		height: 4rem;
		background: white;
		overflow: hidden;
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
		img {
			margin-top: 0.3rem;
		}
	}

	@media (max-width: 991px) {
		ion-avatar {
			width: 3rem;
			height: 3rem;
		}
	}
}

.header-item::part(native) {
	padding-bottom: 10px;
	background: transparent;
}
</style>
