import { createApp } from 'vue';

import Axios from 'axios';

import { createGtm } from 'vue-gtm';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import 'animate.css';

import App from './App.vue';
import router from './router';
import store from './store';

/* BV Styles */
import './theme/global.scss';

import './registerServiceWorker';

const app = createApp(App).use(IonicVue).use(router);

// Set up GTM - disabled by default for GDPR reasons
app.use(
	createGtm({
		id: 'GTM-PGC6578', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
		defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
		compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
		enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
		// debug: true, // Whether or not display console logs debugs (optional)
		loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
		vueRouter: router, // Pass the router instance to automatically sync with router (optional)
		//   ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
		trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
	})
);

// Add Store
app.use(store);

app.config.globalProperties.$slugify = (str) => {
	str = str.replace(/^\s+|\s+$/g, '');

	// Make the string lowercase
	str = str.toLowerCase();

	// Remove accents, swap ñ for n, etc
	var from =
		'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;';
	var to =
		'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';
	for (var i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}

	// Remove invalid chars
	str = str
		.replace(/[^a-z0-9 -]/g, '')
		// Collapse whitespace and replace by -
		.replace(/\s+/g, '-')
		// Collapse dashes
		.replace(/-+/g, '-');

	return str;
};

// Set global Axios
app.config.globalProperties.$http = Axios;
// app.config.globalProperties.$http.defaults.baseURL = 'http://localhost:8055';
app.config.globalProperties.$http.defaults.baseURL =
	'https://cms.biohandel.app';

// Logout of App if we get 401 from API
app.config.globalProperties.$http.interceptors.response.use(
	undefined,
	function (err) {
		return new Promise(function (resolve, reject) {
			console.log('intercept', err.response);
			if (
				err.response.status === 401 &&
				!localStorage.getItem('token') &&
				err.response.config.url !== '/auth/login'
			) {
				console.log('refresh token expired -> logging out');
				store
					.dispatch('logout')
					.then(() => router.go('/login'))
					.catch((err) => console.log(err));
			} else if (
				err.response.status === 401 &&
				localStorage.getItem('token')
			) {
				console.log('refresh auth token');
				store.dispatch('refresh');
			} else if (err.response.status === 401) {
				console.log('logged out and unauth error');
				reject(err);
			} else {
				resolve();
			}
			reject(err);
		});
	}
);

// Set token is available
const token = localStorage.getItem('auth_token');
if (token) {
	app.config.globalProperties.$http.defaults.headers[
		'Authorization'
	] = `Bearer ${token}`;
}

router.isReady().then(() => {
	app.mount('#app');
});
