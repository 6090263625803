import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '../store';
import Login from '../views/Login.vue';

const routes = [
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/invite',
		name: 'invite',
		component: () => import('../views/Invite.vue')
	},

	{
		path: '/',
		name: 'start',
		component: () => import('../views/Start.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/kurse',
		name: 'kurse',
		component: () => import('../views/Kurse.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/kurs/:id/:name',
		name: 'kurseDetail',
		component: () => import('../views/KursDetail.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/kurs/:kurs/:name/kapitel/:kapitel/modul/:module',
		name: 'module',
		component: () => import('../views/Module.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/advertorial/:kurs/:name/kapitel/:kapitel/modul/:module',
		name: 'advertorial',
		component: () => import('../views/Advertorials.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/wawi',
		name: 'wawi',
		component: () => import('../views/WaWi.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/wawi/:id/:name',
		name: 'wawiDetail',
		component: () => import('../views/WaWiDetail.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/mitarbeiter',
		name: 'mitarbeiter',
		component: () => import('../views/Mitarbeiter.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/mitarbeiter/:id',
		name: 'mitarbeiterDetail',
		component: () => import('../views/MaDetail.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/profil',
		name: 'profil',
		component: () => import('../views/Profil.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('../views/News.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/news/:id/:name',
		name: 'newsDetail',
		component: () => import('../views/NewsDetail.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/uber',
		name: 'uber',
		component: () => import('../views/Uber.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/uber/:id',
		name: 'uberDetail',
		component: () => import('../views/UberDetail.vue'),
		meta: {
			requiresAuth: true
		}
	}
];
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (store.getters.isLoggedIn) {
			next();
			return;
		}
		next('/login');
		return;
	}
	next();
});

export default router;
